var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-lg-550px bg-white rounded p-10 p-lg-15 mx-auto"},[_c('b-form',{staticClass:"form w-100 fv-plugins-bootstrap5 fv-plugins-framework",attrs:{"novalidate":"novalidate"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"text-center mb-10"},[_c('h1',{staticClass:"text-dark mb-3"},[_vm._v("Change Login Password")])]),_c('div',{staticClass:"mb-10 fv-row fv-plugins-icon-container",attrs:{"data-kt-password-meter":"true"}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Old Password")]),_c('div',{staticClass:"position-relative mb-3"},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"type":!_vm.showPassword ? 'password' : 'text',"placeholder":"","state":_vm.validateState('password_current'),"autocomplete":"off"},model:{value:(_vm.form.password_current),callback:function ($$v) {_vm.$set(_vm.form, "password_current", $$v)},expression:"form.password_current"}}),_c('span',{staticClass:"btn btn-sm btn-icon position-absolute translate-middle top-50 end-20 me-n2",on:{"click":_vm.togglePassword}},[_c('i',{staticClass:"la la-eye-slash fs-2",class:{
                'd-none': _vm.showPassword
              }}),_c('i',{staticClass:"la la-eye fs-2",class:{
                'd-none': !_vm.showPassword
              }})])],1)])]),_c('div',{staticClass:"fv-row mb-10 fv-plugins-icon-container"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("New Password")]),_c('div',{staticClass:"position-relative mb-3"},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"type":!_vm.showPasswordNew ? 'password' : 'text',"placeholder":"","state":_vm.validateState('password_new'),"autocomplete":"off"},model:{value:(_vm.form.password_new),callback:function ($$v) {_vm.$set(_vm.form, "password_new", $$v)},expression:"form.password_new"}}),_c('span',{staticClass:"btn btn-sm btn-icon position-absolute translate-middle top-50 end-20 me-n2",on:{"click":_vm.togglePasswordNew}},[_c('i',{staticClass:"la la-eye-slash fs-2",class:{
              'd-none': _vm.showPasswordNew
            }}),_c('i',{staticClass:"la la-eye fs-2",class:{
              'd-none': !_vm.showPasswordNew
            }})])],1),_c('div',{staticClass:"fv-plugins-message-container invalid-feedback"})]),_c('div',{staticClass:"fv-row mb-10 fv-plugins-icon-container"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Confirm New Password")]),_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"type":"password","placeholder":"","state":_vm.validateState('confirmPassword'),"autocomplete":"off"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('div',{staticClass:"fv-plugins-message-container invalid-feedback"})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-lg btn-primary fw-bolder btn-block",attrs:{"type":"submit","disabled":_vm.loading,"id":"kt_new_password_submit"}},[(!_vm.loading)?_c('span',{staticClass:"indicator-label"},[_vm._v("Confirm changes")]):_vm._e(),(_vm.loading)?_c('span',{staticClass:"indicator-progress"},[_vm._v("Please wait... "),_c('span',{staticClass:"spinner-border spinner-border-sm align-middle ms-2"})]):_vm._e()])]),_c('div')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }